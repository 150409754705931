import React from "react"
import "./about.css"
import ME from "../../assets/me.jpg"
import { GoLocation } from "react-icons/go"
import { TbLanguageKatakana } from "react-icons/tb"
import { BsFillPencilFill } from "react-icons/bs"

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <GoLocation className="about__icon" />
              <h5>Location</h5>
              <small>Toronto, Canada</small>
            </article>

            <article className="about__card">
              <BsFillPencilFill className="about__icon" />
              <h5>Education</h5>
              <small>MS in Computer Science</small>
            </article>

            <article className="about__card">
              <TbLanguageKatakana className="about__icon" />
              <h5>Languages</h5>
              <small>English, Mandarin, Cantonese</small>
            </article>
          </div>

          <p>
            A go-getter with eminent analytical skills and cross-cultural
            experiences. Keen to seek full-time opportunities in Software
            Development. Spending each day learning something new, currently
            interested in ReactJS and Smart Contracts.
          </p>

          <small>
            <p>
              Fun Fact: Java Road is named after the Dutch shipping firm
              Koninklijke Java-China Paketvaart Lijnen which had its operational
              headquarters in Hong Kong in 1900s.
            </p>
          </small>

          <a href="mailto:lwongnm@gmail.com" className="btn btn-primary">
            Contact Me
          </a>
        </div>

        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
