import React from "react"
import "./header.css"
import CTA from "./CTA"
import HeaderSocials from "./HeaderSocials"
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai"

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <div className="name">
          <h3>Hello I'm</h3>
          <h1>Lok WONG</h1>
        </div>

        <div className="me">
          <span className="wave">👋</span>
        </div>
        <CTA />
      </div>
    </header>
  )
}

export default Header
