import React from "react"
import "./portfolio.css"

import IMG1 from "../../assets/grpc-logo.png"
import IMG2 from "../../assets/trade.jpg"
import IMG3 from "../../assets/nft.jpg"
import IMG4 from "../../assets/discord_chatbot.jpg"
import IMG5 from "../../assets/budgeting_app.jpg"
import IMG6 from "../../assets/andriod.jpg"

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import VideoIframe from "./VIdeoIframe.tsx"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

const data = [
  {
    video:
      "https://drive.google.com/file/d/10EziicYh6oaSimW-K3Sv9TYID8PfWwI8/preview",
    title: "Fuzzy Logic Agent For Racing (Course: Game AI)",
    functions:
      "Implemented a Fuzzy Logic Agent that can race on a procedurally generated track and achieved a 46 Km/h average speed on the track over 5 minutes with no wipeout. (Post work on Github is an honor code violation.)",
    github: false,
    link: "#",
    iframe: true,
  },
  {
    image: IMG1,
    video: null,
    title:
      "GRPC and Distributed File Systems (Course: Graduate Introduction to Operating Systems)",
    functions:
      "Applied gRPC and Protocol Buffers to design and implement a file transfer protocols that followed a weakly consistent synchronization design to manage cache consistency between multiple clients and a server. Developed functions including fetching a file from a server, storing a file to a server, preventive writer locks that only allow one writer per file at a time, auto synchronization between clients and server, multi-threaded server. (Post work on Github is an honor code violation.)",
    github: false,
    link: "#",
    iframe: false,
  },
  {
    image: IMG2,
    video: null,
    title: "Stock Trading Journal Analysis Website (Work in process)",
    functions:
      "Developed back-end with Django and a responsive front-end with Bootstrap and applied Chart.js for data visualization. Designed an algorithm for identifying closed trades and calculating respective PnL for 20,000 trading records within 1 min.",
    github: false,
    link: "#",
    iframe: false,
  },
  {
    image: IMG6,
    video: null,
    title: "Job Offer Comparison App (Course: Software Development Process)",
    functions:
      "Built an andriod application to help people compare job offers with benefits, in different locations, and other aspects beyond salary.  (Post work on Github is an honor code violation.)",
    github: false,
    link: "#",
    iframe: false,
  },
  {
    image: IMG3,
    video: null,
    title: "Demo Ethereum Non-Fungible Token (NFT)",
    functions:
      "Developed and implemented ERC721 standard smart contract to create NFT collections using Solidity. The smart contract was optimized through merkle tree data structure for whitelisted buyers. It is deployed in the Ethereum Mainnet and listed in https://opensea.io/",
    github: true,
    link: "https://github.com/lok-w/ERC721A-NFT-contract",
    iframe: false,
  },
  {
    image: IMG4,
    video: null,
    title: "Discord Chatbot",
    functions:
      "Built a Discord chatbot using Python for members to submit and validate their eligibility of whitelist sale. The chatbot applied Google Sheet API as a database to store and retrieve member data.",
    github: true,
    link: "https://github.com/lok-w/discord-bot-eligibility-checker",
    iframe: false,
  },
  {
    image: IMG5,
    video: null,
    title: "Budgeting App",
    functions:
      "Built a desktop application for managing personal accounts and tracking expenses using Java. Applied JavaFX and CSS for GUI to visualize the data entries and intuitive user input form.",
    github: true,
    link: "https://github.com/lok-w/budgeting-app",
    iframe: false,
  },
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Projects</h5>
      <h2>Portfolio</h2>

      <Swiper
        className="container portfolio__container"
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        {data.map(
          ({ image, video, title, functions, github, link, iframe }, index) => {
            if (github) {
              return (
                <SwiperSlide key={index} className="portfolio__item">
                  <div className="portfolio__item-image">
                    <img src={image} alt="" />
                  </div>
                  <h3>{title}</h3>
                  <p>{functions}</p>

                  <div className="portfolio__item-cta">
                    <a
                      href={link}
                      className="portfolio__item-cta btn btn-primary"
                      target="_blank"
                    >
                      Github
                    </a>
                  </div>
                </SwiperSlide>
              )
            } else if (iframe) {
              return (
                <SwiperSlide key={index} className="portfolio__item">
                  <div className="portfolio__item-video">
                    <VideoIframe videoURL={video} />
                  </div>
                  <h3>{title}</h3>
                  <p>{functions}</p>
                </SwiperSlide>
              )
            } else {
              return (
                <SwiperSlide key={index} className="portfolio__item">
                  <div className="portfolio__item-image">
                    <img src={image} alt="" />
                  </div>
                  <h3>{title}</h3>
                  <p>{functions}</p>
                </SwiperSlide>
              )
            }
          }
        )}
      </Swiper>
    </section>
  )
}

export default Portfolio
