import React from "react"
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai"

const CTA = () => {
  return (
    <div className="cta">
      {/* <a href={Resume} download className='btn'>Download Resume</a> */}

      <a
        href="https://www.linkedin.com/in/wonglok/"
        target="_blank"
        className="header__socials"
      >
        <AiFillLinkedin />
      </a>

      <a href="#about" className="btn btn-primary">
        About Me
      </a>

      <a
        href="https://github.com/lok-w"
        target="_blank"
        className="header__socials"
      >
        <AiFillGithub />
      </a>
    </div>
  )
}

export default CTA
